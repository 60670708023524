import "vant/es/toast/style";
import _Toast from "vant/es/toast";
import _objectSpread from "/Users/yuqingying/Documents/ranJing/project/frontend/school-sport-test-h5-teacher/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/web.dom-collections.for-each.js";
import { defineComponent, reactive, toRefs, onUnmounted, onMounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useStore } from '@/store';
import { selectType, navBar } from '@/constant/constants';
import { sexList, avatarFlagList } from '@/constant/gradeAndClassesAndSex';
import { mybus, MittTypes } from '@/hooks';
import { isEmpty, prefixZero, avatarUrl } from '@/utils/func';
import cVanPopup from '@/components/cVanPopup.vue';
import cVanNavBar from '@/components/cVanNavBar.vue';
export default defineComponent({
  props: {},
  components: {
    cVanPopup: cVanPopup,
    cVanNavBar: cVanNavBar
  },
  setup: function setup() {
    var router = useRouter();
    var route = useRoute();
    var store = useStore();
    var i = 0;
    var dataMap = reactive({
      selectedType: 0,
      navBar: {
        title: '学生信息',
        color: navBar.WHITE
      },
      loading: false,
      finished: false,
      listData: [],
      gradeTitle: '',
      clazzTitle: '',
      sexTitle: '',
      avatarFlagTitle: '',
      queryParam: {
        grade: '',
        clazz: '',
        sex: '',
        avatar_flag: '',
        keywords: '',
        page: 0,
        limit: 14
      }
    });
    var queryGrade = isNaN(Number(route.query.grade)) ? '' : Number(route.query.grade);
    var queryClazz = isNaN(Number(route.query.clazz)) ? '' : Number(route.query.clazz);
    var querySex = isNaN(Number(route.query.sex)) ? '' : Number(route.query.sex);
    var avatarFlag = isNaN(Number(route.query.avatar_flag)) ? '' : Number(route.query.avatar_flag);

    if (!isEmpty(queryGrade)) {
      if (queryGrade != 0) {
        dataMap.queryParam.grade = String(queryGrade);
      }
    }

    if (!isEmpty(queryClazz)) {
      if (queryClazz != 0) {
        dataMap.queryParam.clazz = String(queryClazz);
      }
    }

    if (!isEmpty(querySex)) {
      if (querySex != 0) {
        dataMap.queryParam.sex = String(querySex);
        sexList.map(function (option) {
          if (option.value == querySex) {
            dataMap.sexTitle = option.text;
          }
        });
      }
    }

    if (!isEmpty(avatarFlag)) {
      if (avatarFlag != 0) {
        dataMap.queryParam.avatar_flag = String(avatarFlag);
        avatarFlagList.map(function (option) {
          if (option.value == avatarFlag) {
            dataMap.avatarFlagTitle = option.text;
          }
        });
      }
    }

    function getGrades() {
      store.dispatch('student/getGrades').then(function (res) {
        res.map(function (option) {
          if (!isEmpty(queryGrade)) {
            if (option.grade == queryGrade) {
              dataMap.gradeTitle = option.title;
            }
          }
        });
      });
    }

    function getGroups() {
      store.dispatch('student/getGroups').then(function (res) {
        res.map(function (option) {
          if (!isEmpty(queryGrade) && !isEmpty(queryClazz)) {
            if (option.grade == queryGrade) {
              var groups = option.groups;
              groups.map(function (groupOption) {
                if (groupOption.class_number == queryClazz) {
                  dataMap.clazzTitle = groupOption.title;
                }
              });
            }
          }
        });
      });
    }

    var handleCancel = function handleCancel() {
      dataMap.gradeTitle = '';
      dataMap.clazzTitle = '';
      dataMap.sexTitle = '';
      dataMap.avatarFlagTitle = '';
      dataMap.queryParam.grade = '';
      dataMap.queryParam.clazz = '';
      dataMap.queryParam.sex = '';
      dataMap.queryParam.avatar_flag = '';
      dataMap.queryParam.keywords = '';
      dataMap.queryParam.page = 0;
      dataMap.listData = [];
      i = 0;
      loadMore();
    };

    function resetSearch() {
      dataMap.queryParam.page = 0;
      dataMap.listData = [];
      i = 0;
      loadMore();
    }

    function loadMore() {
      dataMap.queryParam.page += 1; //页数+1

      getList();
    }

    function getList() {
      store.dispatch('student/getStudentListRequest', _objectSpread({}, dataMap.queryParam)).then(function () {
        var total = store.getters["student/listsTotal"];
        var res = store.getters["student/listsData"];
        deal_data(res);
        dataMap.loading = false;

        if (dataMap.queryParam.page == 1) {
          //dataMap.listData = res
          dataMap.listData = JSON.parse(JSON.stringify(res));
        } else {
          if (!isEmpty(res)) {
            dataMap.listData = dataMap.listData.concat(res); //追加数据
          }
        }

        if (res.length == 0 || res.length < dataMap.queryParam.limit || dataMap.listData.length >= total) {
          //数据全部加载完成
          dataMap.finished = true;
        } else {
          dataMap.finished = false;
        }
      });
    }

    function deal_data(result) {
      if (isEmpty(result)) return [];
      result.forEach(function (item) {
        i++;
        item['avatar'] = avatarUrl(item.avatar);

        if (i < 10) {
          item['no'] = prefixZero(i, 2);
        } else {
          item['no'] = i;
        }
      });
    }

    var handleGotoStudentDetail = function handleGotoStudentDetail(item) {
      router.push({
        name: 'studentDetail',
        query: {
          stuId: item.id,
          grade: dataMap.queryParam.grade,
          clazz: dataMap.queryParam.clazz,
          sex: dataMap.queryParam.sex,
          avatar_flag: dataMap.queryParam.avatar_flag
        }
      });
    };

    var handleSelectedType = function handleSelectedType(result) {
      dataMap.selectedType = result;
      var currentValue = '';

      switch (dataMap.selectedType) {
        case selectType.GRADE:
          currentValue = dataMap.queryParam.grade;
          break;

        case selectType.CLAZZ:
          currentValue = dataMap.queryParam.clazz;
          break;

        case selectType.SEX:
          currentValue = dataMap.queryParam.sex;
          break;

        case selectType.AVATAR_FLAG:
          currentValue = dataMap.queryParam.avatar_flag;
          break;
      }

      if (dataMap.selectedType == selectType.CLAZZ && isEmpty(dataMap.queryParam.grade)) {
        _Toast('请先选择年级');
      } else {
        var mResult = {
          selectedType: dataMap.selectedType,
          currentValue: currentValue,
          gradeValue: dataMap.queryParam.grade
        };
        mybus.emit(MittTypes.CHANGE_SELECT_TYPE, mResult);
      }
    };

    mybus.on(MittTypes.SELECTED_RESULT, function (result) {
      var title = result.text;
      var value = result.value;

      switch (dataMap.selectedType) {
        case selectType.GRADE:
          dataMap.gradeTitle = title;
          dataMap.queryParam.grade = value;
          break;

        case selectType.CLAZZ:
          dataMap.clazzTitle = title;
          dataMap.queryParam.clazz = value;
          break;

        case selectType.SEX:
          dataMap.sexTitle = title;
          dataMap.queryParam.sex = value;
          break;

        case selectType.AVATAR_FLAG:
          dataMap.avatarFlagTitle = title;
          dataMap.queryParam.avatar_flag = value;
          break;
      }

      resetSearch();
    });
    onUnmounted(function () {
      mybus.off(MittTypes.SELECTED_RESULT);
    });

    function handleGoBack() {
      router.push({
        name: 'home'
      });
    }

    function handleGotoCreate() {
      router.push({
        name: 'createStudent'
      });
    }

    onMounted(function () {
      getGrades();
      getGroups();
    });
    var refData = toRefs(dataMap);
    return _objectSpread(_objectSpread({}, refData), {}, {
      handleSelectedType: handleSelectedType,
      getList: getList,
      loadMore: loadMore,
      handleCancel: handleCancel,
      selectType: selectType,
      handleGotoStudentDetail: handleGotoStudentDetail,
      resetSearch: resetSearch,
      handleGoBack: handleGoBack,
      handleGotoCreate: handleGotoCreate
    });
  }
});